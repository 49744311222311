<template>
  <flip-quick-stats
    v-if="showLiftSummary"
    class="quick-stat"
    :stats="summaryStatsWithAdditionalPayload"
    :window-type="windowType"
    :loading="loading"
    :show-diff-pill="dates.compareWith && windowType !== WINDOW_TYPE_LIFT"
    :show-diff-pill-with-background="showDiffPillWithBackground"
  />
</template>

<script>
import { pick } from 'underscore';
import moment from 'moment';
import jStat from 'jstat/dist/jstat';
import { sync, get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import performanceDataMixin from '@/components/mixins/performance-data-mixin';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { formatDateForAPI } from '~/util/apiDateFormat';
import * as util from '~/util/utility-functions';
import {
  WINDOW_TYPE_REACH,
  WINDOW_TYPE_IMPRESSION,
  WINDOW_TYPE_LIFT,
  WINDOW_TYPE_CONVERSION,
} from '~/constant';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { formatDateString } from '~/helpers/global/misc-helpers';

export default {
  name: 'SummaryStats',
  components: {
    FlipQuickStats: () =>
      import(/* webpackChunkName: "flip-quick-stats" */ '~/components/flip-quick-stats.vue'),
  },
  mixins: [performanceDataMixin],
  props: {
    windowType: {
      type: String,
      required: true,
      default: '',
    },
    pixel: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      WINDOW_TYPE_LIFT,
      summary: null,
      loading: false,
      summaryStats: [],
      ioPacing: { pacing: {} },
      ioPacingLoading: false,
      ioPacingNoData: true,
      showLiftSummary: this.windowType.toUpperCase() !== WINDOW_TYPE_LIFT.toUpperCase(),
      doSearch: false,
      oldSummaryPayload: null,
      isSearchListenerRegistered: false,
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    dates: get('dashboard/dates'),
    ioOptions: get('dashboard/filters@ioOptions'),
    mediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    selectedIOs: get('dashboard/GET_SELECTED_IOS'),
    selectedIOIds: get('dashboard/GET_SELECTED_IO_IDS'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    showUniques: get('dashboard/filters@showUniques'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    attribution: get('dashboard/filters@attribution'),
    methodology: get('dashboard/filters@methodology'),
    isWatchStats: get('common/isWatchStats'),
    selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),
    universalPixelId: sync('common/universalPixelId'),

    showDiffPillWithBackground() {
      return !this?.account?.organization?.whitelabelEnabled;
    },

    summaryPayload() {
      // const minStartDate = this.fetchMinCampaignStartDate(this.ioOptions);
      // const maxEndDate = this.fetchMaxCampaignEndDate(this.ioOptions);

      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
        mediaType: this.mediaTypes,
        ioIds: this.selectedIOIds,
        startDate: formatDateForAPI(this.dates.startDate),
        endDate: formatDateForAPI(this.dates.endDate),
        adGroups: this.selectedAdGrpOptStr,
        audience: this.selectedAudiences,
        publisher: this.selectedPublishers,
        creative: this.selectedCreatives,
        pixelIds: this.universalPixelId,
      };
      if (this.pixel) {
        payload.pixel = this.pixel;
        payload.event = this.selectedEvent.eventLabel;
        payload.category = this.selectedEvent.category;
        payload.subCategory = this.selectedEvent.subCategories;
        payload.attribution = this.attribution;
        payload.methodology = this.methodology;
        payload.conversionWindow = this.conversionWindow;
        payload.showUniques = this.showUniques;
      }

      if (this.dates.compareWith) {
        payload.compareStartDate = formatDateForAPI(this.dates.compareStartDate);
        payload.compareEndDate = formatDateForAPI(this.dates.compareEndDate);
      }
      return payload;
    },

    summaryStatsWithAdditionalPayload() {
      const result = JSON.parse(JSON.stringify(this.summaryStats));

      result.forEach((item) => {
        if (item.title.toUpperCase() === 'SPEND') {
          item.ioPacing = this.ioPacing;
          item.ioPacingLoading = this.ioPacingLoading;
          item.ioPacingNoData = this.ioPacingNoData;
        }
      });

      return result;
    },
  },
  watch: {
    // summaryPayload: {
    //   deep: true,
    //   async handler(n, o) {
    //     if (util.deepEqual(n, o)) {
    //       return;
    //     }
    //     if (
    //       (this.windowType.toUpperCase() === WINDOW_TYPE_IMPRESSION.toUpperCase() ||
    //         this.windowType.toUpperCase() === WINDOW_TYPE_REACH.toUpperCase()) &&
    //       (n.conversionWindow !== o.conversionWindow || n.showUniques !== o.showUniques)
    //     ) {
    //       return;
    //     }
    //     // Advertiser details required for further processing
    //     if (!this.advertiser && !this.pixel) {
    //       return;
    //     }
    //     if (n.advertiser === o.advertiser && this.isWatchStats[this.windowType]) {
    //       try {
    //         console.log('searching...', n)
    //         await this.fetchSummaryStats(n, this.advertiser.id);
    //       } catch (err) {
    //         console.error('Error in updating summary stats ->', err.message, err);
    //         if (window.$sentry) {
    //           if (err._reported !== true) {
    //             window.$sentry.captureException(err);
    //             err._reported = true;
    //           }
    //         }
    //       }
    //     }
    //     if (!this.isWatchStats[this.windowType.toUpperCase()] && this.pixel) {
    //       const windowTypeUpperCase = this.windowType.toUpperCase();
    //       this.$store.dispatch('common/setIsWatchStats', {
    //         ...this.isWatchStats,
    //         [windowTypeUpperCase]: true,
    //       });
    //     }
    //   },
    // },
  },
  created() {},
  beforeDestroy() {
    this.$store.set(`common/RESET_CONVERSION_WINDOW`);
    if (this.isSearchListenerRegistered) {
      EventBus.$off('do-search', this.handleSearch);
      this.isSearchListenerRegistered = false;
    }
  },
  async mounted() {
    try {
      if (!this.isSearchListenerRegistered) {
        EventBus.$on('do-search', this.handleSearch);
        this.isSearchListenerRegistered = true;
      }
      if (this.advertiser) {
        // console.log('searching from mounted..', this.summaryPayload)
        // await this.fetchSummaryStats(this.summaryPayload, this.advertiser.id);
      }
      await this.loadPerformanceData(this.payload);
    } catch (err) {
      console.error('Error in mounting summary stats ->', err.message, err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    handleSearch(val) {
      if (val) {
        if (this.advertiser) {
          // check the old payload
          if (this.windowType === WINDOW_TYPE_LIFT) {
            this.summaryPayload.io = this.selectedIOs || [];
            if (!this.summaryPayload.io.length) {
              this.summaryPayload.io = '-';
            }
            this.summaryPayload.liftType = 'Brand Awareness';
          } else if (
            this.windowType === WINDOW_TYPE_CONVERSION &&
            this.selectedEvent?.isFalseEvent
          ) {
            this.summaryPayload.kind = this.selectedEvent?.isVcrEvent
              ? 'videoCompletions'
              : 'clicks';
          }

          if (util.deepEqual(this.summaryPayload, this.oldSummaryPayload)) {
            return;
          }
          this.oldSummaryPayload = null;
          this.oldSummaryPayload = this.summaryPayload;
          this.doStatsSearch();
        }
      }
    },
    async doStatsSearch() {
      // await this.fetchSummaryStats(this.summaryPayload, this.advertiser.id);
      if (this.advertiser?.id && this.isWatchStats[this.windowType]) {
        try {
          await this.fetchSummaryStats(this.summaryPayload, this.advertiser.id);
        } catch (err) {
          console.error('Error in updating summary stats ->', err.message, err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        }
      }
      if (!this.isWatchStats[this.windowType.toUpperCase()] && this.pixel) {
        const windowTypeUpperCase = this.windowType.toUpperCase();
        this.$store.dispatch('common/setIsWatchStats', {
          ...this.isWatchStats,
          [windowTypeUpperCase]: true,
        });
      }
    },
    fetchMinCampaignStartDate(campaigns = []) {
      let selectedCampaigns = campaigns.filter((val) => val.checked);

      if (!selectedCampaigns.length) {
        selectedCampaigns = campaigns;
      }
      const startDates = selectedCampaigns.map((filterVal) => filterVal.startDate);
      if (startDates.length === 0) {
        return '';
      }
      const minStartDate = startDates.sort()[0];
      return util.convertEpochToNYTimezone(new Date(minStartDate));
    },
    fetchMaxCampaignEndDate(campaigns = []) {
      let selectedCampaigns = campaigns.filter((val) => val.checked);
      if (!selectedCampaigns.length) {
        selectedCampaigns = campaigns;
      }
      const endDate = new Date(new Date().setDate(new Date().getDate() - 1));

      const endDates = selectedCampaigns.map((filterVal) => filterVal.endDate);
      let maxEndDate = endDates.sort()[endDates.length - 1];
      const yesterday = util.formatFlipDate(endDate);
      if (maxEndDate >= yesterday) {
        maxEndDate = yesterday;
      }
      return util.convertEpochToNYTimezone(new Date(maxEndDate));
    },
    applyParentCSS(showLiftSummary) {
      const parentDiv = document.getElementById('summaryDiv');
      parentDiv?.classList?.remove(showLiftSummary ? 'showDefaultBox' : 'showIncrBox');
      parentDiv?.classList?.add(showLiftSummary ? 'showIncrBox' : 'showDefaultBox');
    },
    isLiftSummaryDataValid(data) {
      return (
        data.brandLift &&
        data.brandLift.liftPercentage > 0 &&
        data.brandLift.significant > 0 &&
        data.incrementality &&
        data.incrementality.liftPercentage > 0 &&
        this.isIncrementalitySignificant(data.incrementality)
      );
    },
    transformData(data) {
      const dataTrans = {};
      const brandLift = {
        exposedRespondents: 0,
        exposedPercentage: 0,
        liftPercentage: 0,
        significant: 0,
      };
      const incrementality = {
        name: 'Overall',
        exposedCount: 0,
        exposedConversions: 0,
        exposedRate: 0,
        controlCount: 0,
        controlConversions: 0,
        controlRate: 0,
        liftPercentage: 0,
        incrementality: 0,
      };
      dataTrans.brandLift = data?.brandLift || brandLift;
      dataTrans.incrementality = data?.incrementality || incrementality;
      dataTrans.brandLift.currentValue = data?.brandLift?.liftPercentage || 0;
      dataTrans.brandLift.isCurrentInfinity = false;
      dataTrans.incrementality.currentValue = data?.incrementality?.liftPercentage || 0;
      dataTrans.incrementality.isCurrentInfinity =
        data?.incrementality?.incrementalityInfinity || false;
      return dataTrans;
    },
    isIncrementalitySignificant(obj) {
      const { exposedRate, exposedCount, controlRate, controlCount } = obj;

      if (exposedRate === 0 || controlRate === 0) {
        return false;
      }
      const pValue = jStat.fn
        .twoSidedDifferenceOfProportions(exposedRate, exposedCount, controlRate, controlCount)
        .toFixed(6);
      return pValue < 0.05;
    },
    validateParams(payload, advertiserId, keys) {
      if (isBlank(advertiserId)) {
        return false;
      }
      if (isBlank(this.universalPixelId)) {
        return false;
      }
      return !util.isAnyBlank(payload, keys);
    },
    async fetchSummaryStats(payload, advertiserId) {
      this.loading = true;

      try {
        if (this.windowType === WINDOW_TYPE_LIFT) {
          payload.io = this.selectedIOs || [];
          if (!payload.io.length) {
            payload.io = '-';
          }
          payload.liftType = 'Brand Awareness';
        } else if (this.windowType === WINDOW_TYPE_CONVERSION && this.selectedEvent?.isFalseEvent) {
          payload.kind = this.selectedEvent?.isVcrEvent ? 'videoCompletions' : 'clicks';
        }

        const summaryParams = buildQueryString(payload);
        const commonKeys = ['client', 'advertiser', 'startDate', 'endDate'];

        this.summaryStats = [];
        if (this.windowType === WINDOW_TYPE_IMPRESSION) {
          const dataValidated = this.validateParams(payload, advertiserId, commonKeys);
          if (dataValidated) {
            const data = await advertiserReportsAPI.impressionsSummary(advertiserId, summaryParams);
            this.processImpressionsResults(data);
          }
        } else if (this.windowType === WINDOW_TYPE_REACH) {
          const reachKeys = [...commonKeys, 'xandrId'];
          const dataValidated = this.validateParams(payload, advertiserId, reachKeys);
          if (dataValidated) {
            const data = await advertiserReportsAPI.performanceMetricsReach(
              advertiserId,
              summaryParams
            );
            this.processReachResults(data);
          }
        } else if (this.windowType === WINDOW_TYPE_LIFT) {
          const liftKeys = [...commonKeys, 'xandrId', 'pixel', 'category', 'io', 'liftType'];
          const dataValidated = this.validateParams(payload, advertiserId, liftKeys);
          if (dataValidated) {
            const data = await advertiserReportsAPI.liftSummary(advertiserId, summaryParams);
            const dataTrans = this.transformData(data);
            this.showLiftSummary = this.isLiftSummaryDataValid(data);
            this.applyParentCSS(this.showLiftSummary);
            this.processLiftResults(dataTrans);
          }
        } else if (
          this.windowType === WINDOW_TYPE_CONVERSION &&
          this.selectedEvent?.isIncrementalReach
        ) {
          const dataValidated = this.validateParams(payload, advertiserId, commonKeys);
          if (dataValidated) {
            const data = await advertiserReportsAPI.incremental_reach_metrics(
              advertiserId,
              summaryParams
            );
            this.$store.dispatch('dashboard/setIncReachMetric', data);
            this.processIncrementalReachResults(data);
          }
        } else if (this.windowType === WINDOW_TYPE_CONVERSION && this.selectedEvent?.isFalseEvent) {
          const dataValidated = this.validateParams(payload, advertiserId, commonKeys);

          if (dataValidated) {
            const data = await advertiserReportsAPI.impressionsSummary(advertiserId, summaryParams);
            this.processVideoImpressionsResults(data);
          }
        } else {
          const conversionKeys = [...commonKeys, 'xandrId', 'pixel', 'category'];
          const dataValidated = this.validateParams(payload, advertiserId, conversionKeys);
          if (dataValidated) {
            const data = await advertiserReportsAPI.conversionsSummary(advertiserId, summaryParams);
            this.processConversionResults(data);
          }
        }
      } catch (err) {
        console.error('Error fetching summary ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async processImpressionsResults(res) {
      this.summaryStats = [];
      const readKeys = ['spend', 'impressions'];
      readKeys.forEach((key) => {
        const objRef = res[key];
        const stat = {};
        stat.title = util.toTitleCase(key);
        stat.value = objRef?.currentValue;
        stat.valueType = util.getValueFormat(key);
        stat.change = {};
        stat.change.value = objRef?.change;
        stat.change.isInfinity = objRef?.isChangeInfinity;
        stat.change.reverseColors = false;
        stat.subValueMuted = true;
        stat.subTitle = null;
        stat.subValue = null;
        stat.subValueType = null;

        this.summaryStats.push(stat);
      });
    },
    async processVideoImpressionsResults(res) {
      this.summaryStats = [];
      const readKeys = this.selectedEvent.isVcrEvent
        ? ['videoCompletions', 'videoCompletionRate']
        : ['clicks', 'clickThroughRate'];
      readKeys.forEach((key, i) => {
        const objRef = res[key];
        const stat = {};
        const eLabel = this.selectedEvent.isVcrEvent
          ? 'Completions'
          : this.selectedEvent.eventLabel;
        stat.title = i === 0 ? eLabel : this.selectedEvent.metric;
        stat.value = objRef?.currentValue;
        stat.valueType = util.getValueFormat(i === 0 ? key : this.selectedEvent.metric);
        stat.change = {};
        stat.change.value = objRef?.change;
        stat.change.isInfinity = objRef?.isChangeInfinity;
        stat.change.reverseColors = false;
        stat.subValueMuted = true;
        stat.subTitle = null;
        stat.subValue = null;
        stat.subValueType = null;

        this.summaryStats.push(stat);
      });
    },
    async processReachResults(res) {
      this.summaryStats = [];
      const readKeys = ['reach', 'frequency'];
      readKeys.forEach((key) => {
        const objRef = res[key];
        const stat = {};
        stat.title = util.toTitleCase(key);
        stat.value = objRef?.currentValue;
        stat.isInfinity = key === 'frequency' && objRef?.isCurrentInfinity;
        stat.valueType = util.getValueFormat(key);
        stat.change = {};
        stat.change.value = objRef?.change;
        stat.change.isInfinity = objRef?.isChangeInfinity;
        stat.change.reverseColors = false;
        stat.subValueMuted = true;
        stat.subTitle = null;
        stat.subValue = null;
        stat.subValueType = null;

        this.summaryStats.push(stat);
      });
    },
    async processIncrementalReachResults(res) {
      this.summaryStats = [];
      const readKeys = ['incrementalReach', 'incrementalReachPercentage'];
      readKeys.forEach((key, i) => {
        const objRef = res[key];
        const stat = {};
        stat.title = util.toTitleCase(i === 0 ? 'Inc. Reach' : 'Inc. Reach %');
        stat.value = objRef?.currentValue;
        stat.isInfinity = objRef?.isCurrentInfinity;
        stat.valueType = util.getValueFormat(key);
        stat.change = {};
        stat.change.value = objRef?.change;
        stat.change.isInfinity = false;
        stat.change.reverseColors = false;
        stat.subValueMuted = true;
        stat.subTitle = null;
        stat.subValue = null;
        stat.subValueType = null;
        this.summaryStats.push(stat);
      });
    },
    async processLiftResults(res) {
      this.summaryStats = [];
      const readKeys = ['incrementality', 'brandLift'];
      readKeys.forEach((key, i) => {
        const objRef = res[key];
        const stat = {};
        stat.title = util.toTitleCase(i === 0 ? 'Incremental Lift' : 'Brand Lift');
        stat.value = objRef?.currentValue;
        stat.isInfinity = objRef?.isCurrentInfinity;
        stat.valueType = util.getValueFormat(key);
        stat.change = {};
        stat.change.value = objRef?.change;
        stat.change.isInfinity = false;
        stat.change.reverseColors = false;
        stat.subValueMuted = true;
        stat.subTitle = null;
        stat.subValue = null;
        stat.subValueType = null;

        this.summaryStats.push(stat);
      });
    },
    async processConversionResults(res) {
      this.summaryStats = [];
      const readKeys = this.selectedEvent.isRevenueEvent ? ['revenue', 'roas'] : ['visits', 'cpa'];
      readKeys.forEach((key, i) => {
        const objRef = res[key];
        const stat = {};
        stat.title = i === 0 ? this.selectedEvent.eventLabel : this.selectedEvent.metric;
        stat.titleIncludes = i === 0 ? this.selectedEvent.subEventLabels : [];
        stat.value = objRef?.currentValue;
        stat.isInfinity = ['roas', 'cpa'].includes(key) && objRef?.isCurrentInfinity;
        stat.valueType = util.getValueFormat(key);
        stat.change = {};
        stat.change.value = objRef?.change;
        stat.change.isInfinity = objRef?.isChangeInfinity;
        stat.change.reverseColors = key === 'cpa';
        stat.subValueMuted = true;
        stat.subTitle = null;
        stat.subValue = null;
        stat.subValueType = null;

        this.summaryStats.push(stat);
      });
    },
    async loadPerformanceData(payload) {
      payload = { ...payload };

      // Advertiser details required for further processing
      if (!this.advertiser || !this.universalPixelId) {
        return;
      }
      try {
        await this.fetchIoPacing(
          pick(payload, ['client', 'advertiser', 'mediaType', 'ioIds', 'adGroups'])
        );
      } catch (err) {
        console.error('error loading loadPerformanceMetricsData ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      }
    },
    async fetchIoPacing(payload) {
      if (!this.selectedIOs || (Array.isArray(this.selectedIOs) && !this.selectedIOs.length)) {
        this.ioPacingNoData = true;
        return;
      }

      if (this.windowType !== WINDOW_TYPE_IMPRESSION) {
        return;
      }

      this.ioPacingLoading = true;
      this.ioPacingNoData = false;
      try {
        payload.mediaType = '';
        payload.ioIds = payload.ioIds.join(',');
        const data = await advertiserReportsAPI.ioPacing(this.advertiser.id, payload);
        if (data.pacing) {
          data.startDate = moment(formatDateString(data.startDate), 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          );
          data.endDate = moment(formatDateString(data.endDate), 'YYYY-MM-DD').format('MM/DD/YYYY');
          this.ioPacing = data;
        } else {
          this.ioPacingNoData = true;
        }
      } catch (err) {
        console.error('error fetching io pacing ->', err);
        this.ioPacingNoData = true;
        throw err;
      } finally {
        this.ioPacingLoading = false;
      }
    },
  },
};
</script>
